import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FabricFileIconComponent } from './fabric-file-icon.component';
import { FabricFileIconService } from './fabric-file-icon.service';

@NgModule({
  declarations: [FabricFileIconComponent],
  imports: [CommonModule],
  exports: [FabricFileIconComponent]
})
export class FabricFileIconModule {
  static forRoot(): ModuleWithProviders<FabricFileIconModule> {
    return {
      ngModule: FabricFileIconModule,
      providers: [FabricFileIconService]
    };
  }
}
