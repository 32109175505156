import { Injectable, Input } from '@angular/core';
import { DEFAULT_BASE_URL, GENERIC_FILE, FileTypeIconMap } from './fabric-file-icon-constants';

@Injectable({
  providedIn: 'root'
})
export class FabricFileIconService {
  private _extensionToIconName: { [key: string]: string };
  constructor() {
    this.initIconBag();
  }
  initIconBag() {
    if (!this._extensionToIconName) {
      this._extensionToIconName = {};

      for (const iconName in FileTypeIconMap) {
        if (FileTypeIconMap.hasOwnProperty(iconName)) {
          const extensions = FileTypeIconMap[iconName].extensions;

          if (extensions) {
            for (let i = 0; i < extensions.length; i++) {
              this._extensionToIconName[extensions[i]] = iconName;
            }
          }
        }
      }
    }
  }
  baseUrl() {
    return DEFAULT_BASE_URL;
  }
  getFileTypeIconNameFromExtension(extension: string): string {
    // Strip periods, force lowercase.
    extension = extension.replace('.', '').toLowerCase();

    return this._extensionToIconName[extension] || GENERIC_FILE;
  }
}
